import PropTypes from "prop-types"
import React from "react"
import { Grid } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  footerContent: {
    flexGrow: 1,
    backgroundColor: '#f7d373',
    color: '#444',
    padding: '10px 10px 10px 10px',
    zIndex: 0,
    fontSize: '14px'
  },
  footerLink: {
    padding: '5px',
    '& a': {
    color: '#444',
    textDecoration: 'none'}
  }
}));
const Footer = () => {
  const classes = useStyles();
  return (<footer>
  <Grid container className={classes.footerContent}>
    <Grid item xs={12}>
      <Grid container justify="center">
        <div className={classes.footerLink}>Copyright © {new Date().getFullYear()}&nbsp;<a href="https://besthavenpoolvilla.com">www.besthavenpoolvilla.com</a>&nbsp;|&nbsp;All rights reserved</div>
      </Grid>
    </Grid>
  </Grid>
</footer>)
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
