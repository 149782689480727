import React from "react"
import PropTypes from "prop-types"
import CssBaseline from "@material-ui/core/CssBaseline"
import { makeStyles } from "@material-ui/core/styles"

// import { Navbar, Nav, NavDropdown } from "react-bootstrap"
import { Navbar, Nav } from "react-bootstrap"

const useStyles = makeStyles(theme => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  dropdown: {
    backgroundColor: "#1a1a1a",
    "&>a": {
      marginTop: "10px !important",
      margin: theme.spacing(1, 1.5),
      fontFamily: `Kanit`,
      color: "#fff !important",
    },
    "&>a:hover": {
      color: "#e2c366 !important",
      backgroundColor: "#1a1a1a",
      boxShadow: "0 2px 0 0 #e2c366",
    },
    "&>div": {
      border: "none",
      backgroundColor: "#1a1a1a",
    },
    "&>div>a": {
      color: "#fff !important",
      fontSize: "14px",
      backgroundColor: "#1a1a1a !important",
    },
    "&>div>a:hover": {
      color: "#e2c366 !important",
      backgroundColor: "#1a1a1a",
    },
    "&>div>a:active": {
      color: "#e2c366 !important",
      backgroundColor: "#1a1a1a",
    },
    "&>div>a:focus": {
      color: "#e2c366 !important",
      backgroundColor: "#1a1a1a",
    },
  },
  appBar: {
    backgroundColor: "#1a1a1a",
    color: "#fff",
    fontFamily: `Kanit`,
    paddingTop: "0px",
    paddingBottom: "0px",
    // height: '75px',
    "& img": {
      marginBottom: "0px",
    },
  },
  link: {
    fontSize: "17px",
    fontFamily: `Kanit`,
    margin: theme.spacing(1, 1.5),
    color: "#fff !important",
    "&:hover": {
      color: "#e2c366 !important",
      boxShadow: "0 2px 0 0 #e2c366",
    },
  },
}))

const Header = ({ siteTitle }) => {
  const classes = useStyles()
  return (
    <React.Fragment>
      <CssBaseline />
      <Navbar className={classes.appBar} expand="lg" variant="dark">
        <Navbar.Brand href="/">
          {" "}
          <img
            height="75px"
            src={`${process.env.AWS_S3_URL}/assets/besthaven-white.png`}
            alt={siteTitle}
          ></img>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto navbar-dark">
            <Nav.Link className={classes.link} href="/">
              หน้าแรก
            </Nav.Link>
            <Nav.Link className={classes.link} href="/AboutUs">
              เกี่ยวกับเรา
            </Nav.Link>
            {/* <NavDropdown
              className={classes.dropdown}
              title="บ้านพัก"
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item href="#action/3.1">
                บ้านพูลวิลล่าสำหรับ 8-15 ท่าน
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                บ้านพูลวิลล่าสำหรับ 16-25 ท่าน
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">
                บ้านพูลวิลล่าสำหรับ 25+ ท่าน
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.4">
                บ้านพักตากอากาศ
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.4">
                บ้านพักสำหรับจัดสัมมนา
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link className={classes.link} href="#">
              โปรโมชั่น
            </Nav.Link> */}
            <Nav.Link className={classes.link} href="/CustomerReview">
              รีวิวจริงจากลูกค้า
            </Nav.Link>
            <Nav.Link className={classes.link} href="/ContactUs">
              ติดต่อเรา
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </React.Fragment>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
