import React from 'react';

class StuckContent extends React.Component {
  render() {
    const { children, modifiers } = this.props;

    const modifierClasses = modifiers.length ? modifiers.reduce((result, modifier) => {
      result = `${result} ${StuckContent.baseClass}--${modifier}`;
      return result;
    }, '') : '';

    return (
      <div className={`${StuckContent.baseClass} ${modifierClasses}`}>
        {children}
      </div>
    );
  }
}

StuckContent.baseClass = 'StuckContent';

export default StuckContent;
